var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"tab-default"},[_c('v-tabs',{attrs:{"flat":"","hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.id,attrs:{"ripple":false}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabContent),function(item){return _c('v-tab-item',{key:item.id,attrs:{"transition":"fade-transition"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"single-tab-content"},[_c('ul',_vm._l((item.content),function(item,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":"/service"}},[_vm._v(_vm._s(item.title)+" "),_c('span',[_vm._v(" - "+_vm._s(item.name))])]),_vm._v(" "+_vm._s(item.desc)+" ")],1)}),0),(item.Semua)?_c('div',{staticClass:"rn-blog-area ptb--50 bg_color--1"},[_c('v-container',[_c('v-row',_vm._l((_vm.edukasi),function(edu){return _c('v-col',{key:edu.id,attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[(edu.kategori_artikel == 'Gambar')?_c('div',[_c('div',{staticClass:"card h-100 shadow-sm border-0 rounded-lg"},[_c('div',{staticClass:"content"},[_c('img',{staticStyle:{"width":"100%","height":"220px","border-top-left-radius":"0.3rem","border-top-right-radius":"0.3rem"},attrs:{"src":edu.gambar,"frameborder":"0"}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(edu.judul)+" ")])])])])]):(edu.kategori_artikel == 'Video')?_c('div',[_c('div',{staticClass:"card h-100 shadow-sm border-0 rounded-lg"},[_c('div',{staticClass:"content"},[_c('iframe',{staticStyle:{"width":"100%","height":"220px","border-top-left-radius":"0.3rem","border-top-right-radius":"0.3rem"},attrs:{"src":'https://www.youtube.com/embed/' +
                                edu.embed_video,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(edu.judul)+" ")]),_c('div',{staticClass:"card-art"},[_vm._v(" "+_vm._s(edu.isi_artikel.substring(0, 50) + "..")+" ")])])])])]):_c('div',[_c('div',{staticClass:"card h-100 shadow-sm border-0 rounded-lg"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(edu.judul)+" ")]),_c('div',{staticClass:"card-art"},[_vm._v(" "+_vm._s(edu.isi_artikel.substring(0, 250) + "..")+" ")]),_c('div',{staticClass:"content_footer"},[_c('router-link',{staticClass:"rn-btn",attrs:{"to":{
                                  name: 'DataEdukasiDetails',
                                  params: {
                                    id: edu.id,
                                    kategori: edu.kategori_artikel,
                                  },
                                }}},[_vm._v(" Baca Selengkapnya ")])],1)])])])])}),1)],1)],1):_vm._e(),(item.Gambar)?_c('div',{staticClass:"rn-blog-area ptb--50 bg_color--1"},[_c('v-container',[_c('v-row',_vm._l((_vm.edukasiGambar),function(eduG){return _c('v-col',{key:eduG.id,attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"card h-100 shadow-sm border-0 rounded-lg"},[_c('div',{staticClass:"content"},[_c('img',{staticStyle:{"width":"100%","height":"220px","border-top-left-radius":"0.3rem","border-top-right-radius":"0.3rem"},attrs:{"src":eduG.gambar,"frameborder":"0"}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(eduG.judul)+" ")])])])])])}),1)],1)],1):_vm._e(),(item.Vidio)?_c('div',{staticClass:"rn-section ptb--120 bg_color--1"},[_c('v-container',[_c('v-row',_vm._l((_vm.edukasiVidio),function(eduV){return _c('v-col',{key:eduV.id,class:eduV.responisve,attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"card h-100 shadow-sm border-0 rounded-lg"},[_c('div',{staticClass:"content"},[_c('iframe',{staticStyle:{"width":"100%","height":"220px","border-top-left-radius":"0.3rem","border-top-right-radius":"0.3rem"},attrs:{"src":'https://www.youtube.com/embed/' +
                              eduV.embed_video,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(eduV.judul)+" ")]),_c('div',{staticClass:"card-art"},[_vm._v(" "+_vm._s(eduV.isi_artikel.substring(0, 50) + "..")+" ")])])])])])}),1),_c('CoolLightBox',{attrs:{"items":"eduv.embed_video","index":_vm.index},on:{"close":function($event){_vm.index = null}}})],1)],1):_vm._e(),(item.Artikel)?_c('div',{staticClass:"rn-blog-area ptb--50 bg_color--1"},[_c('v-container',[_c('v-row',_vm._l((_vm.edukasiArtikel),function(eduA){return _c('v-col',{key:eduA.id,attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"card h-100 shadow-sm border-0 rounded-lg"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(eduA.judul)+" ")]),_c('div',{staticClass:"card-art"},[_vm._v(" "+_vm._s(eduA.isi_artikel.substring(0, 250) + "..")+" ")]),_c('div',{staticClass:"content_footer"},[_c('router-link',{staticClass:"rn-btn",attrs:{"to":{
                                name: 'DataEdukasiDetails',
                                params: {
                                  id: eduA.id,
                                  kategori: eduA.kategori_artikel,
                                },
                              }}},[_vm._v(" Baca Selengkapnya ")])],1)])])])}),1)],1)],1):_vm._e()])])],1)],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }