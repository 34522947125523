import { render, staticRenderFns } from "./TabPenanggulangan.vue?vue&type=template&id=bffc5652&scoped=true&"
import script from "./TabPenanggulangan.vue?vue&type=script&lang=js&"
export * from "./TabPenanggulangan.vue?vue&type=script&lang=js&"
import style0 from "./TabPenanggulangan.vue?vue&type=style&index=0&id=bffc5652&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bffc5652",
  null
  
)

export default component.exports